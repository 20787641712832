import { render, staticRenderFns } from "./TemplateProject.vue?vue&type=template&id=d960878e&"
import script from "./TemplateProject.vue?vue&type=script&lang=js&"
export * from "./TemplateProject.vue?vue&type=script&lang=js&"
import style0 from "./TemplateProject.vue?vue&type=style&index=0&id=d960878e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionsProjectSectionSelector: require('/app/components/sections/project/SectionSelector.vue').default})
